@tailwind base;
@tailwind components;
@tailwind utilities;

.page {
  margin: 0 !important;
  font-family: 'Calibri', 'Ubuntu', sans-serif;
  text-align: center;
}

.no-select {
  user-select: none;
}

.no-drag {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.img-hover-zoom:hover>img {
  transform: scale(1.035);
}

.text-hover-zoom:hover>div {
  transform: scale(1.025);
}